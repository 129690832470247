<template>
    <div class="promo-congrats">
        <div class="es-dialog__text">
            Поздравляем! Вы получили подарок - промокод на 500 рублей

            <button class="es-button" @click="goToCabinet()">Посмотреть промокод</button>
        </div>
    </div>
</template>

<script>

export default {
  name: 'PromoCongrats',
  components: {},
  data() {
    return {
    };
  },
  computed: {
  },
  methods: {
    goToCabinet() {
      this.$router.push({ name: 'My' });
      this.$store.commit('hideDialog');
    },
  },
};
</script>

<style scoped lang="scss">
 @import "../../../assets/scss/common/variables";
 @import "../../../assets/scss/common/dialog";

.promo-congrats {
    margin: 60px 0 0;

    .es-button {
        margin-top: 35px;
    }
}
</style>

<template>
  <div class="global-message"
       :class="{'global-message--visible': isVisible, 'isRed': color === 'red'}"
       @click="close"
  >
    {{ text }}
  </div>
</template>

<script>

export default {
  name: 'GlobalMessage',
  methods: {
    close() {
      this.$store.commit('hideGlobalMessage');
    },
  },
  computed: {
    text() {
      return this.$store.state.globalMessage.text;
    },
    color() {
      return this.$store.state.globalMessage.color;
    },
    isVisible() {
      return this.$store.state.globalMessage.visible;
    },
  },
};
</script>

<style lang="scss">
.global-message {
  position: fixed;
  min-height: 20px;
  background: #64bd38;
  color: #fff;
  text-align: center;
  width: 100%;
  padding: 5px 10px;
  line-height: 20px;
  font-size: 13px;
  transition: all 200ms;
  transform: translate(0, calc(-100% + 10px)) rotateX(90deg);
  z-index: 60;
  box-sizing: border-box;
  left: 0;
  top: 0;

  &.isRed {
    background: #fa755a;
  }

  &--visible {
    transform: translate(0, 0) rotateX(0deg);
  }
}

</style>

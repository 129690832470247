import axios from 'axios';

export default ({
  state: {
    isFullPackageSelected: true,
    isOpened: false,
    priceToPay: 0,
    priceWithoutDiscount: 0,
    isYaPayVisible: false,
    cardErrorText: '',
    promoDiscount: 0,
    promoDiscountValue: 0,
    promoDiscountType: '',
    activatedPromoCode: null,
    localPromoError: false,
    minTotalForPromo: 0,
    isLandingPageCoursePayment: false,
    isModulePagePayment: false,
    isSinglePagePayment: false,
    isConsultationPayment: false,
    isModulePaymentFull: true,
    landingPaymentType: 'base',
    landingPaymentPriceToPay: 0,
    landingPaymentSavings: 0,
    landingPaymentFullPrice: 0,
    currentPaymentModule: false,
    webinarPaymentData: {},
  },
  mutations: {
    setWebinarPaymentData(state, data) {
      state.webinarPaymentData = data;
    },
    setCurrentPaymentModule(state, data) {
      state.currentPaymentModule = data;
    },
    setLandingPaymentType(state, data) {
      state.landingPaymentType = data;
    },
    setLandingPaymentPriceToPay(state, data) {
      state.landingPaymentPriceToPay = data;
    },
    setLandingPaymentSavings(state, data) {
      state.landingPaymentSavings = data;
    },
    setLandingPaymentFullPrice(state, data) {
      state.landingPaymentFullPrice = data;
    },
    setLandingPagePayment(state, data) {
      state.isLandingPageCoursePayment = data;
    },
    setSinglePagePayment(state, data) {
      state.isSinglePagePayment = data;
    },
    setConsultationPayment(state, data) {
      state.isConsultationPayment = data;
    },
    setModulePagePayment(state, data) {
      state.isModulePagePayment = data;
    },
    clearAllPaymentFlags(state) {
      state.isModulePagePayment = false;
      state.isConsultationPayment = false;
      state.isSinglePagePayment = false;
      state.isLandingPageCoursePayment = false;
    },
    setModuleLenght(state, data) {
      state.isModulePaymentFull = data;
    },
    changeMinTotalForPromo(state, data) {
      state.minTotalForPromo = data;
    },
    setLocalPromoError(state, data) {
      state.localPromoError = data;
    },
    setPriceWithoutDiscount(state, data) {
      state.priceWithoutDiscount = data;
    },
    setPromoDiscount(state, data) {
      state.promoDiscount = data;
    },
    setPromoDiscountValue(state, data) {
      state.promoDiscountValue = data;
    },
    setPromoDiscountType(state, data) {
      state.promoDiscountType = data;
    },
    setPromoCode(state, data) {
      state.activatedPromoCode = data;
    },
    setCardErrorText(state, data) {
      state.cardErrorText = data;
    },
    setPriceToPay(state, data) {
      state.priceToPay = data;
    },
    setPackagesSwithcer(state, data) {
      state.isFullPackageSelected = data;
    },
    showPayment(state) {
      state.isOpened = true;
    },
    hidePayment(state) {
      state.isOpened = false;
      state.isFullPackageSelected = true;
      state.localPromoError = false;
      state.promoDiscount = 0;
      state.activatedPromoCode = null;
      state.isLandingPageCoursePayment = false;
    },
    hideYaPay(state) {
      state.isYaPayVisible = false;
    },
    showYaPay(state) {
      state.isYaPayVisible = true;
    },
  },
  actions: {
    addUserToWebinar({ commit, getters }) {
      const currentUrl = getters.getLocalUrl;

      axios
        .get(`${currentUrl}/index.php?route=api/meditation/addUserToWebinar/`, {
          params: {
            name: getters.webinarPaymentData.userName,
            mail: getters.webinarPaymentData.userEmail,
            webinarID: getters.flowWebinarZoomID,
          },
        })
        .then((response) => {
          if (response.data.success) {
            console.log(response.data);
            commit('showGlobalMessage', `Оплата прошла успешно. Данные отправленны на почту ${getters.webinarPaymentData.userEmail}`);
          } else {
            commit('showGlobalError', 'Произошла ошибка, обратитесь к менеджеру.');
          }
        });
    },
    showSuccessResult({ commit, getters }) {
      if (getters.getUserMail === '') {
        commit('setCurrentTemplate', 'saveData');
      } else {
        commit('setCurrentTemplate', 'successMessage');
      }

      commit('showDialog');
      commit('setPromoDiscount', 0);
    },
    updateMeditations({ commit, getters, dispatch }) {
      if (getters.getUserMail === '') {
        return false;
      }

      if (getters.getAuthToken === '') {
        dispatch('logout');
      }

      axios
        .post(`${getters.getLocalUrl}/api/restoreDataFromCabinet.php`, {
          mail: getters.getUserMail,
          token: getters.getAuthToken,
        })
        .then((response) => {
          if (response.data.success) {
            let taskSates = '';

            taskSates = JSON.parse(response.data.taskSates);
            commit('setUserPackage', response.data.packageType);

            localStorage.setItem('adviceList', JSON.stringify(taskSates));
            commit('setDataFromLocalStorage');
          } else {
            if (response.data.authError) {
              commit('showGlobalError', response.data.errorText);
              dispatch('logout');
              window.location.href = '/auth?logouted=true';
            }

            commit('showGlobalError', 'Не удалось обновить данные');
          }
        });

      return true;
    },
  },
  modules: {
  },
  getters: {
    getIsFullPackageSelected: (state) => state.isFullPackageSelected,
    getPriceToPay: (state) => state.priceToPay,
    isLandingPageCoursePayment: (state) => state.isLandingPageCoursePayment,
    coursePaymentType: (state) => state.landingPaymentType,
    webinarPaymentData: (state) => state.webinarPaymentData,
  },
});

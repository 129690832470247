<template>
    <div class="price-description">
        <div class="es-dialog__icon-cover">
            <div class="es-dialog__icon"></div>
        </div>
        <div class="es-dialog__text">
            <div class="es-dialog__list">
                <template v-if="currentMeditation.type === 'webinar'">
                    Стоимость этого вебинара {{currentMeditation.discrPrice}} рублей.
                    В нашем чате телеграм более 10 тысяч человек обсуждают вебинар,
                    оставляют невероятные отзывы и это неудивительно.
                    В свои продукты Елена вкладывает все ценные знания,
                    которые получает во время многочисленных обучений,
                    терапий и личных трансформаций. Это не просто вебинар -
                    это ваша возможность прокачать чувствование себя,
                    отпустить старые стратегии, пройти личную трансформацию,
                    высвободить огромный ресурс для вашего развития
                </template>
                <template v-else>
                    Стоимость этой медитации {{currentMeditation.discrPrice}} рублей.
                    В нашем чате телеграм более 10 тысяч человек обсуждают медитацию,
                    оставляют невероятные отзывы и это неудивительно.
                    В свои продукты Елена вкладывает все ценные знания,
                    которые получает во время многочисленных обучений,
                    терапий и личных трансформаций.
                    Это не просто медитация - это ваша возможность прокачать
                    чувствование себя, отпустить старые стратегии, пройти
                    личную трансформацию, высвободить огромный ресурс для вашего развития.
                </template>
            </div>
        </div>
    </div>
</template>

<script>

export default {
  name: 'PriceDescription',
  components: {},
  data() {
    return {
      complete: false,
    };
  },
  computed: {
    currentMeditation() {
      return this.$store.state.meditations.currentMeditation;
    },
  },
  methods: {
  },
};
</script>

<style scoped lang="scss">
 @import "../../../assets/scss/common/variables";
 @import "../../../assets/scss/common/dialog";

.price-description {
  margin: 40px 0 0;

    .es-dialog__icon {
        background-image: url('/images/features-meditation-icon.svg')
    }

    .es-dialog__list {
        max-height: 330px;
        overflow: auto;
    }
}
</style>

<template>
    <div class="webinar-invite">
        <div class="es-dialog__text">
            Во время эфира я выберу из списка зрителей трех случайных участников.
        </div>

        <button type="button"
                @click="closeDialog()"
                class="es-button  es-button--blue"
        >
            Закрыть
        </button>
    </div>
</template>

<script>

export default {
  name: 'WebinarInvite',
  data() {
    return {
    };
  },
  computed: {
  },
  mounted() {

  },
  methods: {
    closeDialog() {
      this.$store.commit('hideDialog');
    },
  },
};
</script>

<style scoped lang="scss">
 @import "../../../assets/scss/common/variables";
 @import "../../../assets/scss/common/dialog";

.webinar-invite {
  margin: 60px 0 0;

    .es-dialog__text {
        margin-bottom: 30px;
        font-size: 14px;
        line-height: 24px;

        strong {
            font-weight: 600;
        }

        a {
            color: $black;
            text-decoration: underline;
        }
    }
}

 .webinar-instr{
     &__subscribe-variants {
         display: flex;
         justify-content: center;
         align-items: flex-start;
         margin: 25px 0;
     }

     &__subscribe-item {
         font-size: 9px;
         line-height: 14px;
         width: 60px;
         text-align: center;
         margin: 0 8px;
         cursor: pointer;
         display: block;
         text-decoration: none;
         color: $black;
     }

     &__subscribe-image {
         margin-bottom: 8px;

         img {
             width: 43px;
         }
     }
 }
</style>

<template>
    <form class="auth" @submit.prevent="submit">
        <div class="es-input-cover">
            <div class="es-dialog__icon-cover">
                <div class="es-dialog__icon"
                     :style="`background-image: url('${publicPath}images/success-icon.svg')`"
                >
                </div>
            </div>

            <div class="auth__text" v-if="isSaveDialog">
                Оплата {{textForMessage}} прошла успешно.
                Вам открыт доступ в Личный кабинет.<br><br>
                Введите ваш email адрес, чтобы сохранить доступ к Личному кабинету с товарами.
            </div>

            <input type="email" required class="es-input" v-model="mail" placeholder="Ваша почта"/>
        </div>
        <button type="submit" class="es-button es-button--blue">{{buttonText}}</button>
    </form>
</template>

<script>
import axios from 'axios';

export default {
  name: 'Auth',
  props: {
    type: String,
  },
  data() {
    return {
      mail: '',
      publicPath: process.env.BASE_URL,
    };
  },
  computed: {
    isSaveDialog() {
      return this.type === 'saveData';
    },
    buttonText() {
      return this.type === 'saveData' ? 'Сохранить доступ' : 'Войти';
    },
    lastPayedCount() {
      return this.$store.state.meditations.lastPayedCount;
    },
    textForMessage() {
      return this.lastPayedCount > 1 ? 'курса' : 'товара';
    },
    coursePaymentType() {
      return this.$store.state.payment.landingPaymentType;
    },
    meditationInfoCourse() {
      return this.$store.getters.getMeditationsInfoForCourse;
    },
    taskStates() {
      return this.$store.getters.getTaskStates;
    },
  },
  methods: {
    submit() {
      let successMessage = 'Ссылка для восстановления отправлена на почту.';
      this.$store.commit('saveUserMail', this.mail);

      if (this.isSaveDialog) {
        successMessage = 'Данные успешно сохранены';
      }

      axios
        .post(`${this.$store.getters.getLocalUrl}/api/saveUserData.php`, {
          mail: this.$store.getters.getUserMail,
          payed: JSON.stringify(this.taskStates),
          coursePaymentType: this.coursePaymentType,
        })
        .then((response) => {
          if (response.data.success) {
            this.$store.commit('saveAuthToken', response.data.token);
            this.$store.commit('showGlobalMessage', successMessage);
            this.mail = '';
            this.$store.commit('hideDialog');

            if (this.meditationInfoCourse.priceForNoPayed === 0) {
              this.$router.push({ name: 'CabinetFull' });
              return;
            }

            this.$router.push({ name: 'Cabinet' });
          } else {
            this.$store.commit('showGlobalError', response.data.errorText);
          }
        });
    },
    getPayedData() {
      let result = {};

      if (localStorage.getItem('meditationAudio')) {
        const data = JSON.parse(localStorage.getItem('meditationAudio'));
        const isFirstPayed = localStorage.getItem('isAudioPayed');
        result = {
          packages: data.payedPackages,
          payed: data.payed,
        };

        if (isFirstPayed) {
          data.payed.push(1);
        }
      }

      return result;
    },
  },
};
</script>

<style scoped lang="scss">
 @import "../../../assets/scss/common/variables";
 @import "../../../assets/scss/common/dialog";
 @import "../../../assets/scss/common/forms";

.auth {
  margin: 20px 0 0;

    &__text {
        margin-top: 25px;
        margin-bottom: 20px;
        font-size: 14px;
        line-height: 20px;
    }
}
</style>

<template>
    <header class="base-header" :class="{'isNotFixed': isNotFixed}">
        <div class="base-header__logo"><img src="/images/main-logo.svg"></div>
        <div class="base-header__burger" v-if="!isNoBurger">
            <Burger></Burger>
        </div>
    </header>
</template>

<script>

import Burger from './Burger.vue';

export default {
  name: 'BaseHeader',
  props: {
    isNotFixed: String,
    isNoBurger: String,
  },
  components: { Burger },
  data() {
    return {
      publicPath: process.env.BASE_URL,
    };
  },
  computed: {
  },
  methods: {
  },
};
</script>

<style scoped lang="scss">
@import "../../assets/scss/common/variables";

.base-header {
    height: 60px;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    text-align: center;
    line-height: 60px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    z-index: 12;
    padding-left: 24px;
    box-sizing: border-box;
    max-width: $mobile-max;

    @media only screen and (min-width: 768px) {
        left: auto;
    }

    &.isNotFixed {
        position: absolute;

        .base-header__burger {
            position: fixed;
            border: solid 1px $white;
            border-radius: 50%;
            box-shadow: 0 0 3px 1px rgba(255,255,255,0.5);

            @media only screen and (min-width: 768px) {
                left: 50%;
                margin-left: 327px;
            }
        }
    }

    &__logo {
        font-size: 20px;
        font-weight: 600;

        img {
            max-height: 24px;
            position: relative;
            top: 5px;
        }
    }

    &__burger {
        position: absolute;
        width: 32px;
        height: 32px;
        right: 24px;
        top: 14px;
    }
}
</style>

<template>
    <div class="telegram-access">
        <div class="es-dialog__icon-cover">
            <div class="es-dialog__icon"></div>
        </div>
        <div class="es-dialog__text">
            Сразу после оплаты вы сможете добавиться в нашу группу Телеграм.
            В группе более 10 тысяч участниц, там очень интересно и душевно.
        </div>
    </div>
</template>

<script>

export default {
  name: 'TelegramAccess',
  components: {},
  data() {
    return {
      complete: false,
    };
  },
  computed: {
  },
  methods: {
  },
};
</script>

<style scoped lang="scss">
 @import "../../../assets/scss/common/variables";
 @import "../../../assets/scss/common/dialog";

.telegram-access {
  margin: 40px 0 0;

    .es-dialog__icon {
        background-image: url('/images/telegram.svg')
    }
}
</style>

<template>
    <div class="sale-info">
        <div class="sale-info__icon-cover">
            <div class="sale-info__icon">
                <div class="sale-info__text">{{groupDescrData.saleForAll}}%</div>
            </div>
        </div>
        <div class="es-dialog__text">
            При покупке всех товаров этого курса
            вы получаете хорошую скидку -{{groupDescrData.saleForAll}}%
            на все товары. Нажмите на кнопку "Купить по скидке"
        </div>
    </div>
</template>

<script>

export default {
  name: 'SaleInfo',
  data() {
    return {
      complete: false,
    };
  },
  computed: {
    groupDescrData() {
      return this.$store.state.meditations.currentMeditationGroup;
    },
  },
  methods: {

  },
};
</script>

<style scoped lang="scss">
 @import "../../../assets/scss/common/variables";
 @import "../../../assets/scss/common/dialog";

.sale-info {
  margin: 40px 0 0;
    &__icon-cover {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
    }

    &__icon {
        background: url('/images/sale-icon-red.svg');
        width: 63px;
        height: 63px;
    }

    &__text {
        font-size: 14px;
        line-height: 16px;
        font-weight: 700;
        color: $white;
        transform: rotate(-45deg) translate(-5px, 16px);
    }
}
</style>

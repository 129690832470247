<template>
    <div class="unlimited-access">
        <div class="es-dialog__icon-cover">
            <div class="es-dialog__icon"></div>
        </div>
        <div class="es-dialog__text">
            Доступ к товарам остаётся навсегда в личном кабинете
        </div>
    </div>
</template>

<script>

export default {
  name: 'UnlimitedAccess',
  components: {},
  data() {
    return {
      complete: false,
    };
  },
  computed: {
  },
  methods: {
  },
};
</script>

<style scoped lang="scss">
 @import "../../../assets/scss/common/variables";
 @import "../../../assets/scss/common/dialog";

.unlimited-access {
  margin: 40px 0 0;

    .es-dialog__icon {
        background-image: url('/images/infinity.svg')
    }
}
</style>

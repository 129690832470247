<template>
    <div class="burger__cover"
         :class="{'isOpened': isOpened,
         'isLandingCover': isFullMenu,
         'isFixed': notFixed
        }"
    >
        <div class="burger" @click="toggleBurger()">
            <div class="burger__line line-top"></div>
            <div class="burger__line line-middle"></div>
            <div class="burger__line line-bottom"></div>
        </div>
        <div class="burger__menu-cover-white"></div>
        <div class="burger__menu-cover"

        ></div>

            <div class="burger__menu">
                <div class="burger__menu-logo">
                    <img src="/images/main-logo-white.svg">
                </div>
                <div class="burger__center-description" v-if="isCabinet">
                    Вы находитесь в личном кабинете

                    <button class="es-button white" @click="logout()">Выйти</button>
                </div>
                <template v-else>
                    <template v-if="isFullMenu">
                        <div class="burger__menu-list">
                            <div class="burger__menu-item">
                                Главная:
                            </div>

                            <div class="burger__menu-item light"
                                 @click="scrollToLandingPart('authorSectionScrollMarker')"
                            >
                                История моего успеха!
                            </div>
                            <div class="burger__menu-item light"
                                 @click="scrollToLandingPart('paymentSectionScrollMarker')"
                            >
                                Выберите тариф
                            </div>
                            <div class="burger__menu-item light"
                                 @click="scrollToLandingPart('faqSectionScrollMarker')"
                            >
                                Вопрос-ответ
                            </div>
                        </div>
                    </template>
                    <template v-else>
                        <div class="burger__menu-list">
                            <div class="burger__menu-item">
                                Выбрать модуль:
                            </div>
                            <router-link
                    to="/tasks/ya-nichego-ne-boyus/izbavlenie-ot-straha-obidy-viny-i-dr"
                    class="burger__menu-item light"
                            >
                                “Я ничего не боюсь”
                            </router-link>
                            <router-link
                    to="/tasks/ya-prinimayu-sebya/na-blagodarnost-i-prinyatie-sebyas"
                    class="burger__menu-item light"
                            >
                                “Я принимаю себя”
                            </router-link>
                            <router-link
                    to="/tasks/ya-skoro-budu-mamoy/nastroy-na-materinstvo"
                    class="burger__menu-item light"
                            >
                                “Я скоро буду мамой”
                            </router-link>
                            <router-link to="/about" class="burger__menu-item">
                                Автор и ведущая курса
                            </router-link>
                            <router-link to="/faq" class="burger__menu-item">
                                Вопрос-ответ
                            </router-link>
                        </div>
                    </template>

                    <div class="burger__menu-list isBottom">
                        <router-link to="/auth"
                                     class="burger__menu-item isCabinet"
                                     v-if="getUserMail === ''"
                        >
                            Войти в кабинет
                        </router-link>
                        <router-link v-else to="/my" class="burger__menu-item isCabinet">
                            Личный кабинет
                        </router-link>
                    </div>
                </template>
            </div>
    </div>
</template>

<script>

export default {
  name: 'Burger',
  props: {
    notFixed: String,
  },
  data() {
    return {
      complete: false,
      publicPath: process.env.BASE_URL,
    };
  },
  computed: {
    meditationsInfo() {
      return this.$store.getters.getMeditationsInfoForCourse;
    },
    getUserMail() {
      return this.$store.getters.getUserMail;
    },
    isOpened() {
      return this.$store.state.general.isBurgerOpened;
    },
    isFullMenu() {
      return this.meditationsInfo.priceForNoPayed !== 0;
    },
    isCabinet() {
      return this.$route.name === 'Cabinet' || this.$route.name === 'CabinetFull';
    },
  },
  methods: {
    logout() {
      localStorage.removeItem('userEmail');
      localStorage.removeItem('meditationAudio');
      window.location.replace('/');
    },
    scrollToLandingPart(id) {
      if (this.$route.name !== 'WelcomeWebinar' && this.$route.name !== 'Home') {
        this.$router.push({ name: 'Home', params: { section: id } });
        this.$store.commit('hideBurger');

        return false;
      }

      const hiddenElement = document.querySelector(`#${id}`);

      this.$store.commit('hideBurger');
      hiddenElement.scrollIntoView({ block: 'start', behavior: 'smooth' });

      return true;
    },
    toggleBurger() {
      if (this.isOpened) {
        this.$store.commit('hideBurger');
      } else {
        this.$store.commit('showBurger');
      }
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import "../../assets/scss/common/variables";

.burger {
    width: 32px;
    height: 32px;
    background: $main-blue;
    border-radius: 50%;
    position: relative;
    cursor: pointer;
    z-index: 13;

    &__center-description {
        text-align: center;
        font-size: 18px;
        width: calc(100vw - 48px);
        padding-top: 60px;

        .es-button {
            max-width: 250px;
            margin: 30px auto 0 auto;
        }

        @media only screen and (min-width: 768px) {
           width: 700px;
        }
    }

    &__line {
        backface-visibility: hidden;
        position: absolute;
        left: 31%;
        border-top: 2px solid white;
        width: 38%;
        transition: all 0.55s, border-color 0ms;
    }

    .line-top {
        top: 11px;
    }
    .line-middle {
        top: 15px;
    }
    .line-bottom {
        top: 19px;
    }

    &__menu-cover-white {
        position: fixed;
        left: 0;
        top:0;
        background: $white;
        width: 100%;
        height: 100%;
        transition: all 0ms;
        transform-origin: 50% 0;
        transform: scale(0);
    }

    &__menu-list {
        &.isBottom {
            margin: 80px 0 0 0;

            @media only screen and (min-width: 768px) {
                margin: 120px 0 0 0;
            }
        }
    }

    &__menu-cover {
        background: $main-blue;
        width: 200vw;
        height: 690px;
        border-radius: 50%;
        transition: transform 300ms, top 300ms;
        position: fixed;
        margin: -275px 0 0 calc(24px + 32px - 150vw);
        transform: scale(0);
        transform-origin: calc(100% - 20vw - 24px - 10px) calc(50% - 10px);

        /* ipad */
        @media only screen and (min-width: 768px) {
            width: 768px;
            left: 50%;
            margin: 0 0 0 -384px;
            border-radius: 0;
            top: -100vh;
            transform: scale(1);
            height: 450px;

            &:after {
                background: $main-blue;
                position: absolute;
                left:0;
                width: 100%;
                border-radius: 50%;
                height: 140px;
                bottom: -70px;
                content: '';
            }
        }
    }

    &__menu {
        position: fixed;
        left: 24px;
        top: 0;
        color: $white;
        z-index: 11;
        text-align: left;
        visibility: hidden;
        opacity: 0;
        transition: all 0ms;

        /* ipad */
        @media only screen and (min-width: 768px) {
            left: calc(50% - 350px);
        }
    }

    &__menu-logo {
        font-size: 20px;
        font-weight: 600;
        margin-bottom: 24px;
        line-height: 60px;

        img {
            max-height: 24px;
            position: relative;
            top: 5px;
        }
    }

    &__menu-item {
        font-size: 18px;
        font-weight: 600;
        line-height: 24px;
        text-decoration: none;
        color: $white;
        display: block;
        margin-bottom: 24px;
        cursor: pointer;

        &.light {
            font-size: 14px;
            font-weight: 500;
            margin: -10px 0 17px 5px;
            color: #E3ECFF;
            text-shadow: 1px 1px $main-blue;
        }

        &.isCabinet {
            text-shadow: 1px 1px $main-blue!important;
        }

        &.isCabinet {
            &:before {
                background: url("/images/icon-cabinet.svg");
                width: 17px;
                height: 17px;
                display: inline-block;
                margin-right: 10px;
                content: '';
                position: relative;
                top: 2px;
            }
        }
    }

    &__cover {
        &.isLandingCover {
            .burger__menu-cover {
                height: 750px;

                @media only screen and (min-width: 768px) {
                    height: 470px;
                }
            }

            .burger__menu-list.isBottom {
                margin-top: 40px;
            }

            .burger__menu-cover {
                width: 240vw;
                margin: -275px 0 0 calc(24px + 32px - 170vw);

                @media only screen and (min-width: 768px) {
                    width: 768px;
                    margin: 0 0 0 -384px;
                }
            }
        }

        &.isOpened {
            .burger {
                background: $white;
            }

            .burger__menu {
                visibility: visible;
                opacity: 1;
                transition: all 200ms 150ms;
            }

            .burger__menu-cover {
                transform: scale(1);

                @media only screen and (min-width: 768px) {
                    top: 0;
                }
            }

            .burger__menu-cover-white {
                transform: scale(1);
                transition: all 200ms 150ms;
            }

            .burger__line {
                border-top: 2px solid $main-blue;
            }

            .line-top {
                backface-visibility: hidden;
                top: 15px;
                transform: rotate(50deg);
                transition: 0.55s 0.5s;
            }
            .line-middle {
                opacity: 0;
            }
            .line-bottom {
                backface-visibility: hidden;
                top: 15px;
                transform: rotate(-410deg);
                transition: 0.55s 0.5s;
            }
        }
    }
}
</style>

<template>
    <div class="feauture-data">
        <div class="es-dialog__icon-cover">
            <div class="es-dialog__icon"
                 :style="`background-image: url(${publicPath}images/features-meditation-icon.svg)`"
            >

            </div>
        </div>
        <div class="es-dialog__text">
            Ранее купленные товары в стоимость не включены
        </div>
    </div>
</template>

<script>

export default {
  name: 'FeautureButtons',
  props: {
    msg: String,
  },
  components: {},
  data() {
    return {
      publicPath: process.env.BASE_URL,
    };
  },
  computed: {
    getStripeKey() {
      return 'pk_test_EbFCyRpagCLakgHBurKM1Hkk';
    },
  },
  methods: {

  },
};
</script>

<style scoped lang="scss">
 @import "../../../assets/scss/common/variables";
 @import "../../../assets/scss/common/dialog";

.feauture-data {
  margin: 40px 0 0;
}
</style>

export default ({
  state: {
    isVisible: false,
  },
  mutations: {
    showPlayer(state) {
      state.isVisible = true;
      document.querySelector('html').classList.add('playerIsActive');
    },
    hidePlayer(state) {
      state.isVisible = false;
      document.querySelector('html').classList.remove('playerIsActive');
    },
  },
  actions: {
  },
  modules: {
  },
  getters: {

  },
});

import axios from 'axios';

export default ({
  state: {
    meditationsList: [
      {
        groupName: 'vash-instagram',
        title: 'Ваш инстаграм',
        mainColor: '#39BDEB',
        list: [
          {
            url: 'sozdajte_namerenie',
            id: 1,
            groupId: 'vash-instagram',
            isActive: true,
            name: 'Создайте намерение',
            isCompleted: false,
            isInProgress: false,
            discrText: '<p>Когда вы только начинаете свой проект, позвольте себе вообще не думать во что это всё выльется. Доверьтесь пространству! У вас есть только вы и ваши будущие решения. Их сейчас не надо знать, они сформируются в нужный момент. Создайте намерение и двигайтесь вперед с уверенностью в том, что всё получится. </p>'
            + '<p> Получится - не значит, что не будет ошибок. Ошибки нужны, чтобы корректировать свои действия. Очень важно вовремя их замечать, признавать, делать выводы, двигаться дальше. </p>'
            + '<p>Куда двигаться? В неизвестность, там ваше развитие. Представьте, что вы ночью с фонариком прыгаете по кочкам по болотистой местности. Вам достаточно выбрать направление и понять, как прыгнуть на следующую кочку. Такой путь самый неудобный - постоянно нужно выбирать, подсвечивать, разбираться. Но он и самый эффективный - если правильно выбрать направление и уверенно прыгать, то болото быстро закончится)) Ну вы поняли, вперед к звездам!</p>',
            descrBottom: '',
          },
          {
            url: 'napolnite-instagram-iskrennost-yu',
            id: 2,
            groupId: 'vash-instagram',
            isActive: true,
            name: 'Наполните инстаграм искренностью',
            isCompleted: false,
            isInProgress: false,
            discrText: '<p>Следующее, что вам нужно - Инстаграм. Это ваше пространство, от которого многое зависит. Наполните свой Инстаграм искренностью, пользой, собой. </p>'
            + '<p> Перестаньте писать умные цитаты из книг, вместо них делитесь своими личными инсайтами. Пишите посты про события, которые происходят с вами, про свои трансформации. </p>'
            + '<p> 59.4 млн. россиян пользуются Инстаграм каждый месяц (под данным Mediascope на середину 2021 года). Выходите из своей «уютной песочницы». Пишите посты не для друзей и знакомых, вы их меньше всего интересуете, они не ваша целевая аудитория. Друзья вам ставят лайки, но посты ваши не читают. Ваша аудитория значительно больше, чем вы думаете. </p>'
            + '<p>Не пишите один раз в полгода посты-знакомства. Каждый ваш пост должен знакомить с аудиторией, раскрывать вас с разных сторон. Ваш будущий клиент тот, кто сегодня пришел с рекламы, ничего о вас не знает и читает ваш последний пост в ленте.</p>',
            descrBottom: '',
          },
          {
            url: 'stante-temu-kogo-khochetsya-kupit`',
            id: 3,
            groupId: 'vash-instagram',
            isActive: true,
            name: 'Станьте тем, у кого хочется купить',
            isCompleted: false,
            isInProgress: false,
            discrText: ''
            + '<p>Напишите у себя в шапке профиля, в чем вы специализируетесь, чем можете быть полезными подписчикам. Раскрывайте в своих постах себя с профессиональной точки зрения, но не навязывайте свои услуги. В нужный момент люди о вас вспомнят. </p>'
            + '<p>“Если бы у него был курс, я бы точно купил!” Как вам такая фраза? Что надо сделать такого, чтобы про вас так говорили? Надо быть профессионалом своего дела. Надо быть искренним и честным с подписчиками. Надо быть интересным, проявлять творчество. Надо удивлять, делать такое, чего от вас не ждут. Станьте таким/такой и у вас купят и еще попросят!</p>',
            descrBottom: '',
          },
          {
            url: 'vasha-zhizn-tema-dlya-postov',
            id: 4,
            groupId: 'vash-instagram',
            isActive: true,
            name: 'Ваша жизнь - тема для постов',
            isCompleted: false,
            isInProgress: false,
            discrText: ''
            + '<p>Я бы не советовал планировать посты на неделю вперед. В этой идее много “тяжести”, вы сразу себя программируете на то, что “писать посты - это тяжело”, “лучше я сейчас напишу 7 постов и сниму с себя груз ответственности”.</p>'
            + '<p>А что, если подойти к этому процессу с легкостью? Что для этого надо? Искренность, открытость. Проще всего написать про себя, про свой день. Но написать правду, а не придумывать. Так вот, пишите про себя правду и Инстаграм станет вести проще! В конце поста попробуйте сделать общие выводы и поделиться ими с подписчиками. </p>'
            + '<p>В результате, когда вы возьмете за привычку писать о себе настоящем/настоящей, это будет вас стимулировать становиться лучше, а подписчикам будет интересно наблюдать за вашими трансформациями.</p>',
            descrBottom: '',
          },
          {
            url: 'vklyuchaite-nablyudatelnost',
            id: 5,
            groupId: 'vash-instagram',
            isActive: true,
            name: 'Включайте наблюдательность',
            isCompleted: false,
            isInProgress: false,
            discrText: ''
            + '<p>Добавьте в фотографии жизни! Перестаньте постить по 10 фотографий из одной фотосессии. Ваша страница становится красивой, но скучной. Лучше все 10 фотографий разместите в одном посте в фотогалерее. </p>'
            + '<p>Я не фотограф, чтобы останавливаться на этом подробно и учить вас фотографировать. Просто включите наблюдательность. На какие фото в ленте вы обращаете внимание? Это не всегда красивые фото, Инстаграм переполнен “красотой”.  Посмотрите на ленту популярных Инстаграм блогеров и станет понятно, почему они популярные.</p>',
            descrBottom: '',
          },
        ],
      },
      {
        groupName: 'vash-produkt',
        title: 'Ваш продукт',
        mainColor: '#39BDEB',
        list: [
          {
            url: 'pridumaite-prostoi-produkt',
            id: 6,
            groupId: 'vash-produkt',
            isActive: true,
            name: 'Придумайте простой продукт',
            isCompleted: false,
            isInProgress: false,
            discrText: ''
            + '<p>Придумайте простой продукт, который будет стоить от $1 до $5 и предложите его своей аудитории. Это может быть всё что угодно: курс, марафон, медитация, тест, чеклист и др. Главное требование к продукту - он должен приносить пользу людям.\n</p>'
            + '<p>Не пытайтесь довести продукт до совершенства! Выпустите MVP (минимально жизнеспособный продукт), расскажите о нем в сториз и в посте, сделайте первые 5-10 продаж, получите обратную связь от аудитории. И только после этого продолжайте улучшать. Старайтесь, чтобы от идеи до запуска прошло не более 30 дней.</p>'
            + '<p>Когда ваш продукт начнут покупать, подумайте, как улучшить его до $6, до $10 и т.д. Вкладывайте в него весь ваш опыт и знания.</p>',
            descrBottom: '',
          },
          {
            url: 'ne-peregruzhaite-sebya',
            id: 7,
            groupId: 'vash-produkt',
            isActive: true,
            name: 'Не перегружайте себя',
            isCompleted: false,
            isInProgress: false,
            discrText: ''
            + '<p>Важно, чтобы продукт после запуска не отнимал всё ваше время. Вы можете инвестировать свое время только в улучшение продукта, но не в его сопровождение. Всегда оставляйте свободное время в рабочем графике, не перегружайте себя, держите место в голове для чего-то нового.\n</p>'
            + '<p>Потом, когда продукт будет большой, вы сможете добавить очень дорогой тариф с вашим участием, но на старте это вас будет отвлекать от развития продукта.</p>',
            descrBottom: '',
          },
          {
            url: 'ispravlyaite-oshibki',
            id: 8,
            groupId: 'vash-produkt',
            isActive: true,
            name: 'Исправляйте ошибки',
            isCompleted: false,
            isInProgress: false,
            discrText: ''
            + '<p>Заведите чат в телеграм и предоставляйте вход в этот чат тем, кто купит ваш продукт. Там вы сможете отвечать на общие вопросы, это сэкономит много времени. В чате будете получать обратную связь о продукте, она нужна вам, чтобы исправлять ошибки и становиться лучше. </p>'
            + '<p>Улучшать всегда есть что! Заведите список всех задач по улучшению продукта, распределите их по исполнителям и расставьте в порядке приоритета. Какие-то задачи вы будете решать самостоятельно, но часть задач вам необходимо делегировать ответственным специалистам (программисту, дизайнеру, таргетологу и др.)</p>'
            + '<p>Советую пользоваться сервисом <a href="https://trello.com/" target="_blank">www.trello.com</a> - это очень удобный сервис для работы над задачами.</p>',
            descrBottom: '',
          },
          {
            url: 'upakovka-produkta',
            id: 9,
            groupId: 'vash-produkt',
            isActive: true,
            name: 'Упаковка продукта',
            isCompleted: false,
            isInProgress: false,
            discrText: '<p>Когда продукт “взлетит” и начнет приносить хорошие деньги, задумайтесь над красивой упаковкой. Ваш продукт должен быть не только полезным, но красивым и удобным для клиентов. Закажите у дизайнера логотип сайта и фирменный стиль, подумайте как улучшить юзабилити сайта (удобство пользования). \n</p>'
            + '<p>Для сайта <a href="https://elena.shop/" target="_blank">www.elena.shop</a>  я привлекал веб-дизайнера, иллюстратора и дизайнера логотипа. Веб-дизайнера нашел на <a href="https://www.upwork.com/" target="_blank">www.upwork.com</a> , просто выбирал по рейтингу и по адекватности цен. Иллюстратора для графических элементов нашел на <a href="https://pinterest.com/" target="_blank">pinterest.com</a>, просто выбирал красивые картинки, потом связывался с их автором. Дизайнера для логотипа нашел просто по поиску в инстаграм, вбил в строке поиска запрос “создание логотипа”.</p>',
            descrBottom: '',
          },
        ],
      },
      {
        groupName: 'uvelichenie-pribili',
        title: 'Увеличение прибыли',
        mainColor: '#39BDEB',
        list: [
          {
            url: 'vibiraite-pravilnie-celi',
            id: 10,
            groupId: 'uvelichenie-pribili',
            isActive: true,
            name: 'Выбирайте правильные цели',
            isCompleted: false,
            isInProgress: false,
            discrText: '<p>Не зацикливайтесь на увеличении подписчиков, ваша основная цель - увеличение прибыли. Увеличение подписчиков будет естественным условно бесплатным процессом, если вы сумеете выстроить рабочую воронку продаж.\n</p>'
            + '<p>Покупает не тот, кто подписывается на вас в Инстаграм, не тот, кто пишет в директ, не тот, кто заходит на ваш сайт. Покупает тот, кто платит деньги! Выбор правильной цели поможет вам сфокусироваться и принимать правильные решения для её достижения.\n</p>',
            descrBottom: '',
          },
          {
            url: 'postroite-voronku-prodazh',
            id: 11,
            groupId: 'uvelichenie-pribili',
            isActive: true,
            name: 'Постройте воронку продаж',
            isCompleted: false,
            isInProgress: false,
            discrText: '<p>Советую вам использовать гибридную воронку продаж инфопродуктов:</p>'
            + '<ul><li>Работа с органическим трафиком через прогрев в сториз и постах.</li><li>Работа с трафиком от таргетированной рекламы.</li><li>Работа с трафиком через автовебинар.</li></ul>'
            + '<p>Дальше расскажу подробнее про каждый этап.\n</p>'
            + '<p>Как понять, что вы создали эффективную воронку продаж? Вы вкладываете деньги в рекламу → люди видят рекламу, интересуются и покупают ваш продукт → деньги от продажи продукта не только покрывают расходы на рекламу, но и создают вашу прибыль.</p>',
            descrBottom: '',
          },
          {
            url: 'organicheskii-trafik',
            id: 12,
            groupId: 'uvelichenie-pribili',
            isActive: true,
            name: 'Органический трафик',
            isCompleted: false,
            isInProgress: false,
            discrText: '<p>Ваши подписчики - это самые лояльные и адекватные люди во всем Инстаграм. Им легко донести ценность продукта, они дают обратную связь, хорошо покупают ваши продукты. \n</p>'
            + '<p>Основное преимущество - за общение с ними не надо платить! Чтобы вы не написали, ваши посты и сториз увидит какой-то процент подписчиков. Очень важно при работе с органическим трафиком увеличивать активность. Тогда инстаграм будет показывать ваш контент большему количеству подписчиков (увеличивать охват). \n</p>'
            + '<p>Пишите классный контент, давайте ценность, развлекайте, интригуйте, делайте всё чтобы подписчики ставили лайки, сохраняли, комментировали, репостили. Это бесплатная возможность быть услышанным в Инстаграм.\n</p>',
            descrBottom: '',
          },
          {
            url: 'targetirovannaya-reklama',
            id: 13,
            groupId: 'uvelichenie-pribili',
            isActive: true,
            name: 'Таргетированная реклама. ',
            isCompleted: false,
            isInProgress: false,
            discrText: '<p>Сколько бы ни было подписчиков и какой бы контент вы не писали, со временем подписчики выгорают. Все, кто хотел купить уже купили, кто хотел спросить уже спросили. Комментят вас одни и те же лица, продажи падают, денег все меньше, на вас накатывает тоска. \n</p>'
            + '<p>Это значит, что пора выходить из своей «уютной песочницы». Пора подключать платную рекламу. Как правильно это сделать? Настраивать рекламу будет таргетолог, но вы должны понимать принцип работы, чтобы контролировать. Рекомендую рассказать своему таргетологу про воронку, которую я ниже опишу.\n</p>'
            + '<p>Все, кто хоть как-то взаимодействует с вашей компанией собираются в определенные аудитории. Каждой из аудиторий показывается своя реклама (картинки, видео). После просмотра рекламы пользователи каждой из аудиторий должны совершить свои целевые действия:\n</p>'
            + '<p>- Холодная аудитория - это те люди, которые вообще ничего о вас не знают и никогда не заходили в ваш инстаграм. Им надо совершить целевое действие - зайти в ваш инстаграм. После этого они автоматически попадут в следующую аудиторию и им покажется другая реклама.\n</p>'
            + '<p>- Посетители инстаграм - это те, кто был хоть 1 раз у вас в интаграм, но не заходил на ваш сайт. Им надо совершить целевое действие - зайти на ваш сайт. После этого они автоматически попадут в следующую аудиторию и им покажется другая реклама.\n</p>'
            + '<p>- Посетители сайта - это те, кто был у вас на сайте, но не положил товар (продукт) в корзину. Им надо совершить целевое действие - положить товар в корзину. После этого они автоматически попадут в следующую аудиторию и им покажется другая реклама.\n</p>'
            + '<p>- Не завершившие заказ - это те, кто положил в корзину, но не завершил заказ. Им надо совершить целевое действие - завершить заказ. После этого они автоматически попадут в следующую аудиторию и им покажется другая реклама.\n</p>'
            + '<p>- Покупатели - это те, кто уже купили один раз. Им надо сделать повторный заказ. Конец воронки продаж.</p>'
            + '<p>У вас на сайте могут быть дополнительные целевые действия, тогда ваша воронка увеличится. Например на сайте <a href="https://elena.shop/" target="_blank">www.elena.shop</a> есть еще одно промежуточное действие для тех, кто не послушал бесплатный фрагмент медитации. Им предлагается совершить целевое действие - послушать фрагмент бесплатно.\n</p>'
            + '<p>Когда вы “разобьете” всех пользователей на такие группы (этапы воронки), тогда ваша задача сводится к тому, чтобы просто “подталкивать” людей переходить на следующий этап воронки. \n</p>'
            + '<p>У вас появится возможность каждой группе показывать целевую рекламу. Вы не будете просить холодную аудиторию  что-то у вас купить “в лоб”, а просто мягко предложите перейти в ваш аккаунт Инстаграм. Тем, кто посетил ваш сайт вы предложите просто послушать бесплатный фрагмент вашего продукта и так далее. Маленькими шажками, вы сможете продвигать людей к покупке вашего продукта, показывать им те картинки и видео, которые важно увидеть в данный момент времени. \n</p>',
            descrBottom: '',
          },
          {
            url: 'Avtovebinar',
            id: 14,
            groupId: 'uvelichenie-pribili',
            isActive: true,
            name: 'Автовебинар',
            isCompleted: false,
            isInProgress: false,
            discrText: '<p>По мере роста стоимости вашего продукта у людей будет падать мотивация его покупать. Вы всё чаще будете видеть комменты “Дорого!”, “Слишком дорого”, “А за что такие деньги?”</p>'
            + '<p>В рекламе в одной картинке и абзаце текста будет сложно донести всю ценность вашего продукта. Видео-креатив тоже перестанет работать эффективно. Тогда на помощь придёт вебинар! \n</p>'
            + '<p>Вам надо подготовить презентацию и разбить её на 3 части:\n</p>'
            + '<ul>'
            + '<li>знакомство с вами, ваша история с отсылкой к вашему продукту</li>'
            + '<li>ваш продукт, как он поможет людям</li>'
            + '<li>другой полезный и интересный контент.</li>'
            + '</ul>'
            + '<p>Важно, не делать вебинар чисто техническим, для продажи, для галочки. Вложите в него душу, расскажите искренне про свой путь, дайте людям много пользы. </p>'
            + '<p>Сделать анонс вашего вебинара через сториз и посты, при необходимости подключите таргетинг. Скажите, что вебинар будет бесплатным для подписчиков.</p>'
            + '<p>Проведите вебинар на платформе Bizon365. Люди будут смотреть прямую трансляцию вебинара и оставлять комментарии. Прямо на вебинаре вы ненавязчиво презентуете свой продукт, у вас будет от 1.5 до 3 часов, чтобы постепенно донести всю ценность и объяснить почему ваш продукт стоит своих денег.</p>'
            + '<p>Вебинар закончится и вы сможете оценить его эффективность - посчитаете какой процент от присутствующих купит ваш продукт после вебинара. Если результат вас устроит, вы сделаете из вебинара автовебинар.</p>'
            + '<p>Автовебинар - это запись вашего живого вебинара, которая будет автоматически запускаться каждый день или с любой другой периодичностью. Автовебинар будет “крутиться” на той же площадке Bizon365, где проходил живой вебинар. У посетителей будет складываться ощущение, что они присутствуют на живом вебинаре, они будут видеть “старые” комментарии и смогут оставлять свои комментарии.</p>'
            + '<p>Доказано, что в прямые эфиры эффективней, чем видео в записи. У людей создаётся эффект уникальности в режиме «здесь и сейчас» и страх пропустить что-то важное. Поэтому, они больше вовлечены в процесс, внимательно слушают, лучше покупают.</p>',
            descrBottom: '',
          },
          {
            url: 'priyatnie-bonusi',
            id: 15,
            groupId: 'uvelichenie-pribili',
            isActive: true,
            name: 'Приятные бонусы',
            isCompleted: false,
            isInProgress: false,
            discrText: '<p>Когда вы создадите эффективную воронку продаж, вы достигнете еще несколько приятных бонусов.</p>'
            + '<p>Новые подписчики для вас станут бесплатными. Помните, что деньги в рекламу вы вкладываете для продажи продукта и получения прибыли, не для подписчиков. </p>'
            + '<p>Новые клиенты на личные консультации тоже станут бесплатными. После того, как вы запустите рекламу, охваты увеличатся, всё больше новой аудитории узнает о вас. Среди ваших “бесплатных” подписчиков обязательно найдутся те, кто захочет к вам на консультацию. Вам ничего дополнительно не придется делать, только обрабатывать входящие сообщения в директ. </p>',
            descrBottom: '',
          },
          {
            url: 'naidite-targetologa-i-programmista',
            id: 16,
            groupId: 'uvelichenie-pribili',
            isActive: true,
            name: 'Найдите таргетолога и программиста',
            isCompleted: false,
            isInProgress: false,
            discrText: '<p>Я не буду рассказывать подробно про технические детали создания сайта, настройку рекламного кабинета и др. Ваша задача, как собственника, делегировать эти задачи правильным людям:\n</p>'
            + '<ul>'
            + '<li>найдите таргетолога, который будет настраивать рекламу, либо предложите своему мужу (брату, другу и др.) стать вашим партнером и освоить эту профессию. Я сам прошел всего один курс по таргетингу - Таргеториум (<a href="https://targetorium.com/" target="_blank">https://targetorium.com/</a>), советую. Таргетолог подключит ваш аккаунт Инстаграм к рекламному кабинету Facebook и настроит таргетированную рекламу на тех, кому будет интересен ваш новый продукт.\n</li>'
            + '<li>Найдите программиста, который создаст простой сайт с описанием вашего продукта и формой оплаты. Можно воспользоваться конструктором сайтов Тильда <a href="https://tilda.cc/ru/" target="_blank">https://tilda.cc/ru/</a>. Программист так же должен подключить на сайт платежную систему, советую Яндекс.Кассу <a href="https://help-ru.tilda.cc/payments/yookassa" target="_blank">https://help-ru.tilda.cc/payments/yookassa</a></li>'
            + '</ul>',
            descrBottom: '',
          },
        ],
      },
      {
        groupName: 'obrabotka-soobshenii',
        title: 'Обработка сообщений',
        mainColor: '#39BDEB',
        list: [
          {
            url: 'vozmite-dvuh-menedzherov',
            id: 17,
            groupId: 'obrabotka-soobshenii',
            isActive: true,
            name: 'Возьмите двух менеджеров',
            isCompleted: false,
            isInProgress: false,
            discrText: '<p>Первое время после запуска рекламы вы будете наслаждаться перепиской с клиентами, будете себя чувствовать очень востребованным специалистом, жизнь начнет казаться сказкой. Но недолго… Наступит такой момент, когда вы начнете “закапываться” обрабатывая входящие сообщения. Их станет так много, что вы ничего не будете успевать.</p>'
            + '<p>Возьмите двух удаленных менеджеров по обработке входящих сообщений в инстаграм и сделайте им график 2 дня через 2 дня по 8-10 часов в день. Так вы освободите много времени и не будете бояться что-то пропустить.\n</p>'
            + '<p>Просто опубликуйте сообщение в группе фейсбук <a href="https://www.facebook.com/groups/smmjob" target="_blank">https://www.facebook.com/groups/smmjob</a>, что вы ищите менеджера для работы с клиентами в Инстаграм. Всем, кто вам ответит, вышлите ссылку на гугл.форму и попросите заполнить поля (имя, возраст, предыдущий опыт работы, семейное положение, желаемая ЗП в месяц). Когда в списке наберется 10-20 кандидатов, выберите из них 3-4 самых подходящих и договоритесь на созвон по видео связи.\n</p>'
            + '<p>В эти 3-4 кандидата не должны попасть те, у кого есть маленькие дети, если не хотите каждые 2 месяца менять менеджеров. Не советую брать менеджеров старше 35 лет, у них будут высокие требования к вам, как к работодателю. Не советую брать тех, кто в анкете укажет ЗП большую, чем вы можете предложить. Идеальный кандидат - девушка 23-26 лет, из небольшого города, без детей или с детьми старше 5 лет. Посмотрите, какая средняя ЗП в этом городе и предложите ей эту ЗП с учетом того, что работать она будет 15 дней в месяц. </p>'
            + '<p>Просматривайте комментарии и старайтесь отвечайте сами, не шаблонными ответами, тогда подписчикам будет интересно их читать. Те комментарии, которые вы не обработали, пускай обработают менеджеры спустя какое-то время. Чтобы не пропустить новые комментарии, можете воспользоваться сервисом Morecom, он простой и дешевый.</p>',
            descrBottom: '',
          },
          {
            url: 'beregite-Instagram-smolodu',
            id: 18,
            groupId: 'obrabotka-soobshenii',
            isActive: true,
            name: 'Берегите Инстаграм смолоду',
            isCompleted: false,
            isInProgress: false,
            discrText: '<p>Помните, что ваш Инстаграм - в будущем может быть очень ценным активом, минимизируйте риски, никому (слышите!) никому не говорите пароль от вашего Инстаграм и Фейсбук. Ни менеджерам, ни таргетологу, ни самому близкому другу! Подключите Инстаграм к админке Facebook, добавьте туда двух менеджеров и они будут отвечать на сообщения в директ без доступа к вашему Инстаграм.\n</p>'
            + '<p>Вам не надо специальных платных программ для менеджеров, у фейсбука есть отличный бесплатный сервис для обработки входящих сообщений и комментариев - <a href="https://www.facebook.com/help/772447486244207" target="_blank">https://www.facebook.com/help/772447486244207</a></p>'
            + '<p>Не пользуйтесь никакими “серыми” программами по продвижению инстаграм. Во-первых, в настоящее время никакие серые схемы не работают. Во-вторых, вы рискуете быть заблокированными и потерять свой ценный актив.</p>',
            descrBottom: '',
          },
          {
            url: 'Laifhaki-dlya-menedzherov',
            id: 19,
            groupId: 'obrabotka-soobshenii',
            isActive: true,
            name: 'Лайфхаки для менеджеров',
            isCompleted: false,
            isInProgress: false,
            discrText: '<p>Создайте для менеджеров файл в гугл.док, куда будете записывать шаблоны ответов на самые популярные вопросы в директ. Со временем этот файл будет расширяться ответами, а менеджеры будут вас меньше «дёргать».\n</p>'
            + '<p>Заведите в телеграм отдельную группу с менеджерами, куда они будут высылать ссылки на директы клиентов с отзывами и инфоповодами. Один раз в день заходите в эту группу и берите оттуда контент для ваших сториз. Чтобы они не исчезали через 24 часа, создайте папку в прикрепленных сториз.\n</p>'
            + '<p>Создайте в гугл.календаре расписание для своих будущих консультаций, добавьте в календарь менеджеров и они будут записывать клиентов, решать с ними технические детали предстоящей встречи. А вы будете проводить консультации и не отвлекаться на переписки с клиентами.\n</p>',
            descrBottom: '',
          },
          {
            url: 'nastroite-chat-bota-v-Instagram',
            id: 20,
            groupId: 'obrabotka-soobshenii',
            isActive: true,
            name: 'Настройте чат-бота в Инстаграм',
            isCompleted: false,
            isInProgress: false,
            discrText: ''
            + '<p>В разработке...</p>',
            descrBottom: '',
          },
        ],
      },
      {
        groupName: 'lichnaya-transformaciya',
        title: 'Личная трансформация',
        mainColor: '#39BDEB',
        list: [
          {
            url: 'transformaciya-vnutri',
            id: 21,
            groupId: 'lichnaya-transformaciya',
            isActive: true,
            name: 'Трансформация внутри',
            isCompleted: false,
            isInProgress: false,
            discrText: '<p>Если вы психолог, коуч или любой другой специалист, который ведет консультации, продолжайте вести консультации с клиентами, чтобы не терять квалификацию. Но ваша загрузка должна быть в 2-3 раза меньше, а стоимость консультаций в 2-3 раза дороже. Освободившееся время уделите на личные терапии и обучение. \n</p>'
            + '<p>Обучаться профессии надо постоянно - это залог того, что вы будете всегда на плаву. Не жалейте деньги на обучающие курсы и повышение квалификации, они будут вас усиливать как специалиста.\n</p>'
            + '<p>Проходить терапии у психолога надо тем, кто хочет убирать свои страхи, расширять мышление, не просто быть на плаву, но пересаживаться с “лодки на катер”, с “катера на яхту”, с “яхты на корабль”, чтобы выходить из вашего городского водохранилища в открытый океан новых возможностей. \n</p>'
            + '<p>Выбирайте терапевтов, которые стоят дороже, чем вы сами. Это поможет вам расти значительно быстрее.</p>',
            descrBottom: '',
          },
          {
            url: 'transformaciya-snaruzhi',
            id: 22,
            groupId: 'lichnaya-transformaciya',
            isActive: true,
            name: 'Трансформация снаружи',
            isCompleted: false,
            isInProgress: false,
            discrText: '<p>Некоторые люди обучаются, проходят терапии, расширяются внутри, но забывают расширяться снаружи. Живут в “старой” квартире, носят “старую” одежду, ездят в “старые” места отдыхать. Внутри изменились, а про реальную жизнь забыли. Чем они отличаются от “геймеров”, которые сутками просиживают в компьютерных играх, строят там свои миры, большие города, но боятся закончить игру, чтобы не выходить в реальный мир?\n</p>'
            + '<p>Вместе с увеличением дохода начните позволять себе более дорогие вещи, путешествия, еду в ресторанах. Пускай некоторые ваши расходы будут казаться вначале безумно большими, порой даже неадекватными. Не ищите логику, не объясняйте своему мозгу ничего, просто дайте своему телу почувствовать “по-другому”, побудьте в этом, насладитесь. Потом вы заметите, как ваши внешние трансформации начнут ускорять внутренние и наоборот. Лучшая практика для трансформации своей жизни снаружи “подумал - сделал”. Внутри - через мысли, снаружи - через действия!</p>',
            descrBottom: '',
          },
          {
            url: 'ne-utyazhelyaite-sebya',
            id: 23,
            groupId: 'lichnaya-transformaciya',
            isActive: true,
            name: 'Не утяжеляйте себя',
            isCompleted: false,
            isInProgress: false,
            discrText: '<p>Не делайте запись консультаций более, чем на 2 недели вперед. Если боитесь потерять потенциального клиента, просто запишите его в лист ожидания. Если лист ожидания слишком раздувается, это знак, что пора увеличивать стоимость своей консультации.</p>'
            + '<p>Всегда оставляйте время в своих планах для новых идей и экспериментов. Небольшая запись делает вас более свободными и гибкими в своих решениях, будущих планах! Большая запись на несколько месяцев вперед не дает вам развиваться, утяжеляет вас, создает иллюзию стабильности.</p>'
            + '<p>Однажды наступит такой момент, когда вы захотите себя еще больше разгрузить, проводить меньше консультаций, но не терять потенциальных клиентов. Возьмите себе в помощь одного или несколько коллег. Создайте для менеджеров отдельный календарь для записей, берите с каждой консультации 20-30% себе и вкладывайте их в рекламу своего продукта.</p>',
            descrBottom: '',
          },
        ],
      },
      {
        groupName: 'eto-ne-konec-vse-tolko-nachinaetsya',
        title: 'Это не конец, все только начинается',
        mainColor: '#39BDEB',
        list: [
          {
            url: 'ostavte-svoj-otzyv',
            id: 888,
            groupId: 'eto-ne-konec-vse-tolko-nachinaetsya',
            isActive: true,
            name: 'Оставьте свой отзыв',
            isCompleted: false,
            isInProgress: false,
            discrText: '<p>У вас есть уникальная возможность поучаствовать в развитии проекта. Напишите, чего вам не хватило? Какие темы не были затронуты или были недостаточно раскрыты? Перейдите по ссылке, чтобы начать - <a href="https://forms.gle/Fyn9rVPvfvk2tGCcA " target="_blank">https://forms.gle/Fyn9rVPvfvk2tGCcA</a> </p>',
            descrBottom: '',
          },
        ],
      },
    ],
    currentMeditation: {},
    currentMeditationGroup: {},
    legacyCartData: [],
    payedDataForRequest: [],
    lastPayedCount: 0,
  },
  mutations: {
    setAllAsDefault(state) {
      state.meditationsList.forEach((group, groupIndex) => {
        group.list.forEach((task, index) => {
          const currentItem = state.meditationsList[groupIndex].list[index];

          currentItem.isCompleted = false;
          currentItem.isInProgress = false;
        });
      });
    },
    setAsComplete(state, data) {
      state.meditationsList.forEach((group, groupIndex) => {
        group.list.forEach((task, index) => {
          const currentItem = state.meditationsList[groupIndex].list[index];

          if (task.id === data) {
            currentItem.isCompleted = true;
            currentItem.isInProgress = false;
          }
        });
      });
    },
    setAsInProgress(state, data) {
      state.meditationsList.forEach((group, groupIndex) => {
        group.list.forEach((task, index) => {
          const currentItem = state.meditationsList[groupIndex].list[index];

          if (task.id === data) {
            currentItem.isInProgress = true;
            currentItem.isCompleted = false;
          }
        });
      });
    },
    setAsDefault(state, data) {
      state.meditationsList.forEach((group, groupIndex) => {
        group.list.forEach((task, index) => {
          const currentItem = state.meditationsList[groupIndex].list[index];

          if (task.id === data) {
            currentItem.isInProgress = false;
            currentItem.isCompleted = false;
          }
        });
      });
    },
    setCurrentPage(state, data) {
      state.meditationsList.forEach((group, groupIndex) => {
        if (group.groupName === data.group) {
          /* Установим текущую группу */
          state.currentMeditationGroup = group;
          group.list.forEach((meditation, index) => {
            const currentItem = state.meditationsList[groupIndex].list[index];

            currentItem.isActive = false;
            if (meditation.url === data.name) {
              currentItem.isActive = true;
              /* Установим текущую медитацию */
              state.currentMeditation = meditation;
            }
          });
        }
      });
    },
    /* Пометим все не опрлаченные медитации модуля для оплаты. Покупка модуля целиком */
    markCourseMeditationsForPay(state) {
      state.meditationsList.forEach((group, groupIndex) => {
        group.list.forEach((meditation, index) => {
          const currentItem = state.meditationsList[groupIndex].list[index];

          currentItem.isPayPending = false;

          if (!meditation.isPayed) {
            currentItem.isPayPending = true;
          }
        });
      });
    },
    /* Сделаем так, что бы остались помечеными к оплате только медитации из нужной группы */
    markSingleMeditationsForPay(state, payload) {
      state.meditationsList.forEach((group, groupIndex) => {
        group.list.forEach((meditation, index) => {
          const currentItem = state.meditationsList[groupIndex].list[index];

          if (payload !== group.groupName && !meditation.isPayed) {
            currentItem.isPayPending = false;
          }
        });
      });
    },
    /* Пометим все не опрлаченные медитации для оплаты. Покупка курса целиком */
    markModuleMeditationsForPay(state, payload) {
      state.meditationsList.forEach((group, groupIndex) => {
        group.list.forEach((meditation, index) => {
          const currentItem = state.meditationsList[groupIndex].list[index];

          currentItem.isPayPending = false;

          if (payload === group.groupName && !meditation.isPayed) {
            currentItem.isPayPending = true;
          } else {
            currentItem.isPayPending = false;
          }
        });
      });
    },
    markMeditationForPayByID(state, payload) {
      const currentId = payload.id;

      state.meditationsList.forEach((group, groupIndex) => {
        group.list.forEach((meditation, index) => {
          const currentItem = state.meditationsList[groupIndex].list[index];

          if (String(meditation.id) === String(currentId)) {
            currentItem.isPayPending = !currentItem.isPayPending;
          }
        });
      });
    },
    /* Пометим медитации которые идут в оплату */
    markMeditationForPay(state, payload) {
      const currentId = state.currentMeditation.id;
      const currentGroupName = state.currentMeditationGroup.groupName;

      state.meditationsList.forEach((group, groupIndex) => {
        group.list.forEach((meditation, index) => {
          const currentItem = state.meditationsList[groupIndex].list[index];

          currentItem.isPayPending = false;

          if (payload.isFullPackageSelected) {
            if (group.groupName === currentGroupName && !meditation.isPayed) {
              currentItem.isPayPending = true;
            }
          } else if (String(meditation.id) === String(currentId)) {
            currentItem.isPayPending = true;
          }
        });
      });
    },
    /* Помечаем группу купленной целиком */
    markGroupAsPayed(state, data) {
      state.meditationsList.forEach((group, index) => {
        const groupItem = state.meditationsList[index];

        if (group.groupName === data) {
          groupItem.isPayed = true;
        }
      });
    },
    /* Длеаем помеченные медитации оплачеными */
    markAsPayed(state, data) {
      const saveData = {
        payed: [],
        paidForTransaction: [],
        inCart: state.legacyCartData,
        payedPackages: [],
      };
      let dataArray = [];
      let lastPayedCount = 0;

      if (data) {
        dataArray = data.ids.split(',');
      }

      state.meditationsList.forEach((group, groupIndex) => {
        if (group.isPayed) {
          saveData.payedPackages.push(group.groupName);
        }

        group.list.forEach((meditation, index) => {
          const currentItem = state.meditationsList[groupIndex].list[index];

          // если dataArray пустой (оплата не чеерез Яндекс) и медитация в payPending
          if (meditation.isPayPending && !dataArray.length) {
            currentItem.isPayed = true;
            currentItem.isPayPending = false;
            lastPayedCount += 1;
            saveData.paidForTransaction.push(meditation.id);
          }

          // если dataArray есть то оплата была через Яндекс
          // и нужно пробежаться по списку оплаенных
          dataArray.forEach((payedFromYaId) => {
            if (String(payedFromYaId) === String(meditation.id)) {
              currentItem.isPayed = true;
              currentItem.isPayPending = false;
              lastPayedCount += 1;
              saveData.paidForTransaction.push(meditation.id);
            }
          });

          if (meditation.isPayed) {
            saveData.payed.push(meditation.id);
          }
        });
      });

      state.payedDataForRequest = saveData.paidForTransaction;
      state.lastPayedCount = lastPayedCount;
      localStorage.setItem('meditationAudio', JSON.stringify(saveData));
    },
    setDataFromLocalStorage(state) {
      if (localStorage.getItem('adviceList')) {
        const avialibleList = state.meditationsList;
        const data = JSON.parse(localStorage.getItem('adviceList'));

        avialibleList.forEach((group, groupIndex) => {
          group.list.forEach((meditation, index) => {
            const currentItem = state.meditationsList[groupIndex].list[index];

            data.isCompleted.forEach((isCompletedItem) => {
              if (isCompletedItem === meditation.id) {
                currentItem.isCompleted = true;
              }
            });

            data.isInProgress.forEach((isInProgressItem) => {
              if (isInProgressItem === meditation.id) {
                currentItem.isInProgress = true;
              }
            });
          });
        });
      }
    },
  },
  actions: {
    saveTasksState({ state, getters }) {
      const saveData = {
        isCompleted: [],
        isInProgress: [],
      };

      state.meditationsList.forEach((group) => {
        group.list.forEach((task) => {
          if (task.isCompleted) {
            saveData.isCompleted.push(task.id);
          }

          if (task.isInProgress) {
            saveData.isInProgress.push(task.id);
          }
        });
      });

      localStorage.setItem('adviceList', JSON.stringify(saveData));

      const { coursePaymentType } = getters;

      if (getters.getUserMail !== '') {
        axios
          .post(`${getters.getLocalUrl}/api/saveUserData.php`, {
            mail: getters.getUserMail,
            payed: JSON.stringify(getters.getTaskStates),
            coursePaymentType,
          })
          .then((response) => {
            if (response.data.success) {
              console.log('Данные пользователя успешно сохранены');
            }
          });
      }
    },
    savePayedData({ getters }) {
      const { coursePaymentType } = getters;

      if (getters.getUserMail !== '') {
        axios
          .post(`${getters.getLocalUrl}/api/saveUserData.php`, {
            mail: getters.getUserMail,
            payed: JSON.stringify(getters.getTaskStates),
            coursePaymentType,
          })
          .then((response) => {
            if (response.data.success) {
              console.log('Данные пользователя успешно сохранены');
            }
          });
      }

      if (typeof window.fbq !== 'undefined') {
        window.fbq('track', 'Purchase', {
          content_ids: coursePaymentType,
          currency: 'RUB',
          value: getters.getPriceToPay,
        });
      }
    },
  },
  modules: {
  },
  getters: {
    isCurrentPackagePayed: (state) => state.currentMeditationGroup.isPayed,
    getPayedMeditations: (state) => {
      const resultData = {
        count: 0,
        list: [],
      };

      state.meditationsList.forEach((groupItem, groupIndex) => {
        resultData.list.push({
          list: [],
          title: groupItem.title,
          groupName: groupItem.groupName,
          reminigPriceWithSale: 0,
        });

        let fullPrice = 0;

        groupItem.list.forEach((meditationItem) => {
          if (meditationItem.isPayed) {
            resultData.list[groupIndex].list.push(meditationItem);
            resultData.count += 1;
          } else {
            fullPrice += meditationItem.discrPrice;
          }
        });

        fullPrice *= (groupItem.saleForAll / 100);
        resultData.list[groupIndex].reminigPriceWithSale = fullPrice;
      });

      return resultData;
    },
    getTaskStates: (state) => {
      const saveData = {
        isCompleted: [],
        isInProgress: [],
      };

      state.meditationsList.forEach((group) => {
        group.list.forEach((task) => {
          if (task.isCompleted) {
            saveData.isCompleted.push(task.id);
          }

          if (task.isInProgress) {
            saveData.isInProgress.push(task.id);
          }
        });
      });

      return saveData;
    },
    getMeditationsInfoForSingle: (state) => {
      const info = {
        groupsWithNoPayedItems: [],
        noPayedMeditations: [],
        payedMeditations: [],
        readyForPayIds: [],
        totalPrice: 0,
        pendingWebinars: [],
        pendingMeditations: [],
        priceForNoPayed: 0,
        hasPayed: false,
      };

      state.meditationsList.forEach((group) => {
        const groupData = {
          groupTitle: group.title,
          groupName: group.groupName,
          groupImage: group.mainSliderImage,
          groupDescr: group.mainSliderDescr,
          groupLink: `/tasks/${group.groupName}/${group.list[0].url}`,
          noPayedMeditations: [],
          payedMeditations: [],
          isFullModule: true,
          priceForPending: 0,
          noPayedPrice: 0,
        };

        group.list.forEach((item) => {
          info.totalPrice += item.discrPrice;

          if (!item.isPayed) {
            info.priceForNoPayed += item.discrPrice;
            groupData.noPayedPrice += item.discrPrice;

            info.noPayedMeditations.push(item);
            groupData.noPayedMeditations.push(item);

            if (item.isPayPending) {
              info.readyForPayIds.push(item.id);
              groupData.priceForPending += item.discrPrice;

              if (item.type === 'webinar') {
                info.pendingWebinars.push(item.name);
              }

              if (item.type === 'mediatation') {
                info.pendingMeditations.push(item.name);
              }
            }
          } else {
            groupData.isFullModule = false;
            groupData.payedMeditations.push(item);
            info.payedMeditations.push(item);
          }
        });

        info.groupsWithNoPayedItems.push(groupData);
      });

      info.hasPayed = info.totalPrice !== info.priceForNoPayed;

      return info;
    },
    getMeditationsInfoForCourse: (state) => {
      const info = {
        groupsWithNoPayedItems: [],
        meditation: [],
        webinars: [],
        readyForPayIds: [],
        totalPrice: 0,
        priceForNoPayed: 0,
        hasPayed: false,
      };

      state.meditationsList.forEach((group) => {
        const groupData = {
          groupTitle: group.title,
          groupName: group.groupName,
          groupImage: group.mainSliderImage,
          groupDescr: group.mainSliderDescr,
          groupLink: `/tasks/${group.groupName}/${group.list[0].url}`,
          meditations: [],
          webinars: [],
          isFullModule: true,
          noPayedPrice: 0,
        };

        group.list.forEach((item) => {
          info.totalPrice += item.discrPrice;

          if (!item.isPayed) {
            info.priceForNoPayed += item.discrPrice;
            groupData.noPayedPrice += item.discrPrice;

            if (item.type === 'webinar') {
              info.webinars.push(item.name);
              groupData.webinars.push(item.name);
            }

            if (item.type === 'mediatation') {
              info.meditation.push(item.name);
              groupData.meditations.push(item.name);
            }

            if (item.isPayPending) {
              info.readyForPayIds.push(item.id);
            }
          } else {
            groupData.isFullModule = false;
          }
        });

        info.groupsWithNoPayedItems.push(groupData);
      });

      info.hasPayed = info.totalPrice !== info.priceForNoPayed;

      return info;
    },
    getMeditationsInfo: (state) => {
      const info = {
        meditation: [],
        webinars: [],
        readyForPayIds: [],
        totalPrice: 0,
        priceForNoPayed: 0,
        hasPayed: false,
      };

      if (!state.currentMeditationGroup.list) {
        return false;
      }

      state.currentMeditationGroup.list.forEach((item) => {
        info.totalPrice += item.discrPrice;
        if (!item.isPayed) {
          info.priceForNoPayed += item.discrPrice;
          if (item.type === 'webinar') {
            info.webinars.push(item.name);
          }

          if (item.type === 'mediatation') {
            info.meditation.push(item.name);
          }

          if (item.isPayPending) {
            info.readyForPayIds.push(item.id);
          }
        }
      });

      info.hasPayed = info.totalPrice !== info.priceForNoPayed;

      return info;
    },
    getGeneralMeditationInfo: (state) => {
      const info = {
        hasPayed: false,
      };

      state.meditationsList.forEach((group) => {
        group.list.forEach((meditation) => {
          if (meditation.isPayed) {
            info.hasPayed = true;
          }
        });
      });

      return info;
    },
    isLastMeditationOnGroup: (state) => {
      let payedCount = 0;

      state.currentMeditationGroup.list.forEach((item) => {
        if (item.isPayed) {
          payedCount += 1;
        }
      });

      return state.currentMeditationGroup.list.length - payedCount <= 1;
    },
  },
});

<template>
    <div class="cabinet-update">
        <div class="es-dialog__icon-cover">
            <div class="es-dialog__icon"></div>
        </div>
        <div class="es-dialog__text">
            Проверка прошла успешно!<br>
            В личный кабинет загружены все товары.<br><br>

            <span v-if="isCabinet">Если отображаются не все купленные товары,</span>
            <span v-else>Если полный доступ открыт не для всех купленных товаров,</span>
            напишите в директ Инстаграм @elena.siaglo
        </div>
    </div>
</template>

<script>

export default {
  name: 'CabinetUpdateSuccess',
  components: {},
  computed: {
    isCabinet() {
      return this.$store.state.user.updateMeditationSource === 'cabinet';
    },
  },
};
</script>

<style scoped lang="scss">
 @import "../../../assets/scss/common/variables";
 @import "../../../assets/scss/common/dialog";

.cabinet-update {
  margin: 40px 0 0;

    .es-dialog__icon {
        background-image: url('/images/check-icon-green.svg');
        background-size: cover;
        background-color: transparent;
    }
}
</style>

export default ({
  state: {
    cabinetActiveTab: 'refunds',
    usersPayedItems: '',
    usersPayedGroups: '',
    currentWorkingMail: '',
  },
  mutations: {
    setActiveTab(state, payload) {
      state.cabinetActiveTab = payload;
    },
    setUsersPayedItems(state, payload) {
      state.usersPayedItems = payload;
    },
    setUserdPayedGroups(state, payload) {
      state.usersPayedGroups = payload;
    },
    setCurrentWorkingMail(state, payload) {
      state.currentWorkingMail = payload;
    },
  },
  actions: {
  },
  modules: {
  },
  getters: {

  },
});

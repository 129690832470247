export default {
  state: {
    visible: false,
    text: '',
    color: 'green',
  },
  mutations: {
    showGlobalMessage(state, payload) {
      state.text = payload;
      state.visible = true;
      state.color = 'green';

      setTimeout(() => {
        state.visible = false;
      }, 4000);
    },
    hideGlobalMessage(state) {
      state.visible = false;
    },
    showGlobalError(state, payload) {
      state.text = payload;
      state.visible = true;
      state.color = 'red';

      setTimeout(() => {
        state.visible = false;
      }, 4000);
    },
  },
  actions: {
  },
  getters: {

  },
};

<template>
  <div id="app">
    <router-view/>
    <BaseDialog></BaseDialog>
    <GlobalMessage></GlobalMessage>
  </div>
</template>

<script>
import axios from 'axios';
import smoothscroll from 'smoothscroll-polyfill';

import BaseDialog from './components/dialog/BaseDialog.vue';
import GlobalMessage from './components/general/GlobalMessage.vue';

export default {
  name: 'home',
  components: {
    BaseDialog, GlobalMessage,
  },
  mounted() {
    this.$store.commit('setDataFromLocalStorage');
    this.$store.commit('getUserMailFromLocalStorage');
    this.$store.commit('getUserAuthTokenFromLocalStorage');

    const url = new URL(window.location.href);
    const yandexPayedID = url.searchParams.get('yandexPayedID');
    const ids = url.searchParams.get('ids');
    const packageName = url.searchParams.get('packageName');
    const yaPaymentType = url.searchParams.get('type');
    const paymentName = url.searchParams.get('name');
    const paymentEmail = url.searchParams.get('email');

    this.sendFBPixelData();

    this.$store.commit('setUpdateMeditationSource', 'startPage');
    this.$store.dispatch('updateMeditations');

    if (yandexPayedID) {
      if (yaPaymentType === 'webinarPayment') {
        this.checkYaPaymentWebinar(yandexPayedID, paymentName, paymentEmail);
      } else {
        this.checkYaPayment(yandexPayedID, ids, packageName);
      }
    }

    smoothscroll.polyfill();

    /* Проверим нет ли кавычек в почте и если есть чистим */
    this.checkAndClearUserEmail();
    /* Проверим что почта валидная и если это не так, то деавторизуем пользователя */
    this.checkUserEmail();
    /* Проверим что у авторизованого пользователя есть аус токен, если нет то деаторизуем */
    this.checkAuthToken();
  },
  computed: {
    getUserMail() {
      return this.$store.getters.getUserMail;
    },
    getAuthToken() {
      return this.$store.getters.getAuthToken;
    },
  },
  methods: {
    checkAuthToken() {
      if (this.getUserMail !== '' && this.getAuthToken === '') {
        this.$store.dispatch('logout');
      }
    },
    checkUserEmail() {
      const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      const isMailValid = re.test(String(this.getUserMail).toLowerCase());

      if (!isMailValid) {
        this.$store.dispatch('logout');
      }
    },
    checkAndClearUserEmail() {
      const hasQuotes = this.getUserMail.includes('"');

      if (hasQuotes) {
        const updatedEmail = this.getUserMail.replaceAll('"', '');
        this.$store.commit('saveUserMail', updatedEmail);
        this.$store.commit('getUserMailFromLocalStorage');
      }
    },
    sendFBPixelData() {
      /* Не понятно по какой причине,
      но в FB уходят события с почтой, исключаем их наверняка */
      const urlParam = new URL(window.location.href).searchParams.get('email');

      if (typeof window.fbq !== 'undefined' && !urlParam) {
        window.fbq('track', 'PageView');
      }
    },
    checkYaPaymentWebinar(yandexPayedID, name, email) {
      axios
        .post(`${this.$store.getters.getLocalUrl}/api/yandexGetPaymentResult.php`, {
          paymentId: yandexPayedID,
        })
        .then((response) => {
          if (response.data.success) {
            this.$store.commit('setWebinarPaymentData', {
              userName: name,
              userEmail: email,
            });
            this.$store.dispatch('addUserToWebinar');
            window.history.pushState({}, document.title, window.location.pathname);
            this.$router.push({ name: 'WebinarFlowSuccess' });
          } else {
            this.$store.commit('showGlobalError', 'Что то не так с оплатой. обратитесь к администратору.');
          }
        });
    },
    checkYaPayment(yandexPayedID, ids, packageName) {
      axios
        .post(`${this.$store.getters.getLocalUrl}/api/yandexGetPaymentResult.php`, {
          paymentId: yandexPayedID,
        })
        .then((response) => {
          if (response.data.success) {
            if (packageName === 'cabinetConsultation') {
              this.$store.commit('setLandingPaymentType', 'cabinetConsultation');
            }

            if (packageName === 'premium') {
              this.$store.commit('setLandingPaymentType', 'premium');
            }

            this.$store.dispatch('savePayedData', { ids, packageName });
            this.$store.dispatch('showSuccessResult');
            window.history.pushState({}, document.title, window.location.pathname);

            if (packageName === 'module') {
              this.$router.push({ name: 'Cabinet' });
            }
          } else {
            this.$store.commit('showGlobalError', 'Что то не так с оплатой. обратитесь к администратору.');
          }
        });
    },
  },
};
</script>

<style lang="scss">
@import "assets/scss/common/reset";
@import "assets/scss/common/variables";

:root {
  --main-bg-color: $white;
  --main-color: $main-pink;
  --base-url: '/';
  --custom-vh:  0;
}

body {
  background:  var(--main-bg-color);
}

body, html {
  overflow-x: hidden;
}

html.playerIsActive {
  overflow: hidden;

  body {
    overflow: hidden;
  }
}

#app {
  font-family: 'Montserrat', sans-serif;
  width: 100%;
  min-width: $mobile-min;
  max-width: $mobile-max;
  margin: auto;
  color: $black;
}
</style>

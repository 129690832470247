<template>
  <div class="home-page">
    <Header></Header>
    <BaseMainSlider></BaseMainSlider>
  </div>
</template>

<script>
// @ is an alias to /src
import BaseMainSlider from '@/components/BaseMainSlider.vue';
import Header from '@/components/header/BaseHeader.vue';

export default {
  name: 'Home',
  components: {
    BaseMainSlider, Header,
  },
};
</script>

<style lang="scss">
  .home-page {
    padding-top: 60px;
    background: url('/images/main-page-bg.png') no-repeat 50% 0;
    background-size: contain;
  }
</style>

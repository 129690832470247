<template>
    <div class="how-to-listen">
        <div class="es-dialog__icon-cover">
            <div class="es-dialog__icon"
                 :style="`background-image: url('${publicPath}images/how-to-listen-white.svg')`"
            >
            </div>
        </div>

        <ul class="es-dialog__list">
            <li>для лучшего погружения слушайте медитации в наушниках,</li>
            <li>позаботьтесь о том, чтобы во время медитации вас никто не отвлекал</li>
            <li>если вас отвлекли, начните медитацию с начала,</li>
            <li>примите максимально комфортную для вас позу (лежа или сидя) и закройте глаза,</li>
            <li>если медитация ставится на паузу, уберите блокировку экрана
                в настройках телефона,
            </li>
            <li>медитации слушайте только по внутреннему отклику.
                Если есть желание слушать по несколько раз в день, слушайте.
                Если хотите сделать паузу несколько дней, сделайте,
            </li>
            <li>после прослушивания медитаций рекомендуется
                принимать ванну с натуральной морской солью в концентрации 1 кг.
                на 110 литров воды,
            </li>
            <li> если остались вопросы, задайте их в нашем чате
                Телеграм или воспользуйтесь поиском по чату.
            </li>
        </ul>
    </div>
</template>

<script>

export default {
  name: 'HowToListan',
  components: {},
  data() {
    return {
      publicPath: process.env.BASE_URL,
    };
  },
  computed: {
    meditationInfo() {
      return this.$store.getters.getMeditationsInfo;
    },
  },
  methods: {

  },
};
</script>

<style scoped lang="scss">
 @import "../../../assets/scss/common/variables";
 @import "../../../assets/scss/common/dialog";

.how-to-listen {
  margin: 40px 0 0;

    .es-dialog__list {
        max-height: 300px;
        overflow: auto;

        @media only screen and (min-width: 768px) {
            max-height: 550px;
        }
    }
}
</style>

<template>
    <div class="modules-list">
        <div class="es-dialog__icon-cover">
            <div class="es-dialog__icon"
                 :style="`background-image: url('${publicPath}images/meditation-list-icon.svg')`"
            >
            </div>
        </div>

        <ul class="es-dialog__list">
            <li v-for="(meditation, index) in currentPaymentModule" :key="index">
                {{meditation}}
            </li>
        </ul>
    </div>
</template>

<script>

export default {
  name: 'DialogModulesList',
  props: {
    msg: String,
  },
  components: {},
  data() {
    return {
      publicPath: process.env.BASE_URL,
    };
  },
  computed: {
    list() {
      return this.$store.state.meditations.meditationsList;
    },
    currentPaymentModule() {
      const listToShow = [];

      this.list.forEach((group) => {
        group.list.forEach((meditation) => {
          if (meditation.isPayPending) {
            listToShow.push(group.title);
          }
        });
      });

      return new Set(listToShow);
    },
  },
  methods: {

  },
};
</script>

<style scoped lang="scss">
 @import "../../../assets/scss/common/variables";
 @import "../../../assets/scss/common/dialog";

.modules-list {
  margin: 40px 0 0;
}
</style>

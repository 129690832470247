export default ({
  state: {
    userMail: '',
    authToken: '',
    userPromo: null,
    consultationCount: 0,
    adminToken: '',
    updateMeditationSource: 'cabinet',
    landingUserType: '',
    isFlowWebinarTimeOver: false,
    isLandingSaleTimeOver: false,
    isLandingSaleTimeEnabled: false,
    currentUserPackage: '',
  },
  mutations: {
    setUserPackage(state, payload) {
      state.currentUserPackage = payload;
    },
    setLandingSaleTimeEnabled(state, payload) {
      state.isLandingSaleTimeEnabled = payload;
    },
    setLandingSaleTimeOver(state, payload) {
      state.isLandingSaleTimeOver = payload;
    },
    setFlowWebinarTimeOver(state, payload) {
      state.isFlowWebinarTimeOver = payload;
    },
    setConsultationCount(state, payload) {
      state.consultationCount = payload;
    },
    setLandingUserType(state, payload) {
      state.landingUserType = payload;
    },
    setUpdateMeditationSource(state, payload) {
      state.updateMeditationSource = payload;
    },
    saveUserMail(state, payload) {
      state.userMail = payload;

      localStorage.setItem('userEmail', payload);
    },
    saveAuthToken(state, payload) {
      state.authToken = payload;

      localStorage.setItem('authToken', payload);
    },
    getUserMailFromLocalStorage(state) {
      const userMail = localStorage.getItem('userEmail');

      if (userMail) {
        state.userMail = userMail;
      }
    },
    getUserAuthTokenFromLocalStorage(state) {
      const authToken = localStorage.getItem('authToken');

      if (authToken) {
        state.authToken = authToken;
      }
    },
    setUserPromoData(state, data) {
      state.userPromo = data;
    },
    setAdminToken(state, data) {
      state.adminToken = data;
    },
  },
  actions: {
    logout({ state, commit }) {
      state.userMail = '';
      state.authToken = '';
      localStorage.removeItem('userEmail');
      localStorage.removeItem('authToken');
      localStorage.removeItem('adviceList');
      commit('setAllAsDefault');
    },
  },
  modules: {
  },
  getters: {
    getUserMail: (state) => state.userMail,
    getAuthToken: (state) => state.authToken,
    getUserPromo: (state) => state.userPromo,
    getMeditationSource: (state) => state.updateMeditationSource,
  },
});

<template>
    <div class="meditation-list">
        <div class="es-dialog__icon-cover">
            <div class="es-dialog__icon"
                 :style="`background-image: url('${publicPath}images/meditation-list-icon.svg')`"
            >
            </div>
        </div>

        <ul class="es-dialog__list" v-if="isSinglePagePayment">
            <li v-for="(meditationName, index) in singleMeditations" :key="index">
                {{meditationName}}
            </li>
        </ul>

        <ul class="es-dialog__list" v-else>
            <li v-for="(meditationName, index) in meditationInfo.meditation" :key="index">
                {{meditationName}}
            </li>
        </ul>
    </div>
</template>

<script>

export default {
  name: 'DialogMeditationList',
  props: {
    msg: String,
  },
  components: {},
  data() {
    return {
      publicPath: process.env.BASE_URL,
    };
  },
  computed: {
    meditationInfo() {
      return this.$store.getters.getMeditationsInfo;
    },
    isSinglePagePayment() {
      return this.$store.state.payment.isSinglePagePayment;
    },
    meditationInfoSingle() {
      return this.$store.getters.getMeditationsInfoForSingle;
    },
    singleMeditations() {
      return this.meditationInfoSingle.pendingMeditations;
    },
  },
  methods: {

  },
};
</script>

<style scoped lang="scss">
 @import "../../../assets/scss/common/variables";
 @import "../../../assets/scss/common/dialog";

.meditation-list {
  margin: 40px 0 0;
}
</style>

<template>
    <div class="webinar-invite">
        <div class="es-dialog__text" v-if="isFirstStep">
            Хотите посетить бесплатный вебинар "От бесплодия к счастливому материнству"?
        </div>

        <div class="es-dialog__text" v-if="isSecondStep">
            Вам в какое время удобно? В 15.00 или в 19.00 по Москве?
        </div>

        <div class="es-dialog__text" v-if="is1500">
            Остался последний шаг. Выберите куда прислать ссылку на вебинар: в телеграм или вайбер.

            <div class="webinar-instr__subscribe-variants">
                <a href="tg://resolve?domain=elena_psybot&start=ml3" class="webinar-instr__subscribe-item">
                    <div class="webinar-instr__subscribe-image">
                        <img src="/images/icon-telegram.svg"/>
                    </div>
                    <div class="webinar-instr__subscribe-text">Telegramm</div>
                </a>
                <a href="viber://pa?chatURI=elena-psy&context=ml3" class="webinar-instr__subscribe-item">
                    <div class="webinar-instr__subscribe-image">
                        <img src="/images/icon-viber.svg"/>
                    </div>
                    <div class="webinar-instr__subscribe-text">Viber</div>
                </a>
            </div>
        </div>

        <div class="es-dialog__text" v-if="is1900">
            Остался последний шаг. Выберите куда прислать ссылку на вебинар: в телеграм или вайбер.

            <div class="webinar-instr__subscribe-variants">
                <a href="tg://resolve?domain=elena_psybot&start=ml2" class="webinar-instr__subscribe-item">
                    <div class="webinar-instr__subscribe-image">
                        <img src="/images/icon-telegram.svg"/>
                    </div>
                    <div class="webinar-instr__subscribe-text">Telegramm</div>
                </a>
                <a href="viber://pa?chatURI=elena-psy&context=ml2" class="webinar-instr__subscribe-item">
                    <div class="webinar-instr__subscribe-image">
                        <img src="/images/icon-viber.svg"/>
                    </div>
                    <div class="webinar-instr__subscribe-text">Viber</div>
                </a>
            </div>
        </div>

        <template v-if="isFirstStep">
            <button type="button" @click="showSecondStep()" class="es-button es-button--blue">
                ДА ХОЧУ
            </button>
            <button type="button" @click="closeDialog()" class="es-button es-button--link">
                НЕТ, УЖЕ ПОСЕТИЛА
            </button>
        </template>

        <template v-if="isSecondStep">
            <button type="button" @click="showIs1500()" class="es-button es-button--blue">
                В 15.00
            </button>
            <button type="button" @click="showIs1900()" class="es-button es-button--blue">
                В 19.00
            </button>
        </template>
    </div>
</template>

<script>

export default {
  name: 'WebinarInvite',
  data() {
    return {
      isFirstStep: true,
      isSecondStep: false,
      is1500: false,
      is1900: false,
    };
  },
  computed: {
  },
  mounted() {

  },
  methods: {
    closeDialog() {
      this.$store.commit('hideDialog');
    },
    showSecondStep() {
      this.isFirstStep = false;
      this.isSecondStep = true;
    },
    showIs1500() {
      this.isSecondStep = false;
      this.is1500 = true;
    },
    showIs1900() {
      this.isSecondStep = false;
      this.is1900 = true;
    },
  },
};
</script>

<style scoped lang="scss">
 @import "../../../assets/scss/common/variables";
 @import "../../../assets/scss/common/dialog";

.webinar-invite {
  margin: 60px 0 0;

    .es-dialog__text {
        margin-bottom: 30px;
        font-size: 14px;

        strong {
            font-weight: 600;
        }
    }
}

 .webinar-instr{
     &__subscribe-variants {
         display: flex;
         justify-content: center;
         align-items: flex-start;
         margin: 25px 0;
     }

     &__subscribe-item {
         font-size: 9px;
         line-height: 14px;
         width: 60px;
         text-align: center;
         margin: 0 8px;
         cursor: pointer;
         display: block;
         text-decoration: none;
         color: $black;
     }

     &__subscribe-image {
         margin-bottom: 8px;

         img {
             width: 43px;
         }
     }
 }
</style>

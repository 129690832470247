import { render, staticRenderFns } from "./PriceDescription.vue?vue&type=template&id=56a25b1f&scoped=true&"
import script from "./PriceDescription.vue?vue&type=script&lang=js&"
export * from "./PriceDescription.vue?vue&type=script&lang=js&"
import style0 from "./PriceDescription.vue?vue&type=style&index=0&id=56a25b1f&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "56a25b1f",
  null
  
)

export default component.exports
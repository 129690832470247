import Vue from 'vue';
import VueRouter from 'vue-router';
import Home from '../views/Home.vue';

Vue.use(VueRouter);

const routes = [
  {
    path: '/start',
    name: 'Start',
    meta: { title: 'Сам себе продюсер' },
    component: Home,
  },
  {
    path: '/welcomeVideo',
    name: 'WelcomeVideo',
    meta: { title: 'Сам себе продюсер' },
    component: () => import(/* webpackChunkName: "my" */ '../views/WebinarSuccess.vue'),
  },
  {
    path: '/start-koabmhuss',
    meta: { title: 'От бесплодия к счастливому материнству.' },
    name: 'WebinarInstructionsEU',
    component: () => import(/* webpackChunkName: "my" */ '../views/WebinarInstructions.vue'),
  },
  {
    path: '/start-kowog6sx20',
    meta: { title: 'От бесплодия к счастливому материнству.' },
    name: 'WebinarInstructionsAsia',
    component: () => import(/* webpackChunkName: "my" */ '../views/WebinarInstructions.vue'),
  },
  /* Лендинг для страницы продажи участия в зум конфернции */
  { path: '/flow1', redirect: '/flow2' },
  {
    path: '/flow2',
    name: 'WebinarLanding',
    meta: {
      title: 'Второй поток исцеления',
      description: 'Волшебное пространство, где происходят массовые метаморфозы сознания.',
    },
    component: () => import(/* webpackChunkName: "my" */ '../views/WebinarLanding.vue'),
  },
  {
    path: '/flowSuccess',
    name: 'WebinarFlowSuccess',
    meta: {
      title: 'Поток исцеления',
      description: 'Волшебное пространство, где происходят массовые метаморфозы сознания.',
    },
    component: () => import(/* webpackChunkName: "my" */ '../views/WebinarPaymentSuccess.vue'),
  },
  // {
  //   path: '/flow1-taweh42w',
  //   name: 'Flow1-record-repeat',
  //   meta: {
  //     title: 'Поток исцеления',
  //     description: 'Волшебное пространство, где происходят массовые метаморфозы сознания.',
  //   },
  //   component: () => import(/* webpackChunkName: "my" */ '../views/WebinarPaymentSuccess.vue'),
  // },
  {
    path: '/flow2-taweh3lw',
    name: 'Flow1-record',
    meta: {
      title: 'Поток исцеления',
      description: 'Волшебное пространство, где происходят массовые метаморфозы сознания.',
    },
    component: () => import(/* webpackChunkName: "my" */ '../views/WebinarPaymentSuccess.vue'),
  },
  {
    path: '/summary',
    name: 'Summary',
    meta: { title: 'Сам себе продюсер' },
    component: () => import(/* webpackChunkName: "my" */ '../views/Summary.vue'),
  },
  /* Лендинг со скидкой с каунтером на сутки */
  {
    path: '/welcomeWebinar',
    name: 'WelcomeWebinar',
    meta: { title: 'Сам себе продюсер' },
    component: () => import(/* webpackChunkName: "my" */ '../views/Welcome.vue'),
  },
  /* Лнединг для покупки модулей с каунторм на 3 дня */
  {
    path: '/supermodule',
    name: 'Supermodule',
    meta: { title: 'Сам себе продюсер' },
    component: () => import(/* webpackChunkName: "my" */ '../views/Supermodule.vue'),
  },
  /* Лендинг для покупки отдлеьных медитаций с каунторм на 3 дня */
  {
    path: '/finalprice',
    name: 'Finalprice',
    meta: { title: 'Сам себе продюсер' },
    component: () => import(/* webpackChunkName: "my" */ '../views/FinalPrice.vue'),
  },
  /* Обычный лендинг со скидкой, но скаунторм на 3 дня */
  {
    path: '/specialprice',
    name: 'WelcomeWebinar36',
    meta: { title: 'Сам себе продюсер' },
    component: () => import(/* webpackChunkName: "my" */ '../views/Welcome.vue'),
  },
  /* Лендинг без скидки */
  {
    path: '/',
    name: 'Home',
    meta: { title: 'Сам себе продюсер' },
    component: () => import(/* webpackChunkName: "my" */ '../views/Welcome.vue'),
  },

  /* Новый кабинет лендинг */
  {
    path: '/my',
    name: 'Cabinet',
    meta: { title: 'Сам себе продюсер' },
    component: () => import(/* webpackChunkName: "my" */ '../views/Cabinet.vue'),
  },
  {
    path: '/cabinet',
    name: 'CabinetFull',
    meta: { title: 'Сам себе продюсер' },
    component: () => import(/* webpackChunkName: "my" */ '../views/Cabinet.vue'),
  },
  {
    path: '/admin',
    name: 'Admin',
    meta: { title: 'Сам себе продюсер' },
    component: () => import(/* webpackChunkName: "my" */ '../views/Admin.vue'),
  },
  {
    path: '/about',
    name: 'About',
    meta: { title: 'Сам себе продюсер' },
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue'),
  },
  {
    path: '/auth',
    name: 'Auth',
    meta: { title: 'Сам себе продюсер' },
    component: () => import(/* webpackChunkName: "auth" */ '../views/Auth.vue'),
  },
  {
    path: '/restore',
    name: 'Restore',
    meta: { title: 'Сам себе продюсер' },
    component: () => import(/* webpackChunkName: "auth" */ '../views/Auth.vue'),
  },
  {
    path: '/faq',
    name: 'FAQ',
    meta: { title: 'Сам себе продюсер' },
    component: () => import(/* webpackChunkName: "faq" */ '../views/FAQ.vue'),
  },
  {
    path: '/agreement',
    name: 'Agreement',
    meta: { title: 'Сам себе продюсер' },
    component: () => import(/* webpackChunkName: "agreement" */ '../views/Agreement.vue'),
  },
  {
    path: '/tasks/:id/:meditationId',
    name: 'MeditationGroup',
    meta: { title: 'Сам себе продюсер' },
    component: () => import(/* webpackChunkName: "meditation" */ '../views/MeditationGroup.vue'),
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  document.title = to.meta.title;

  if (to.meta.description) {
    document.querySelector('meta[name=description]')
      .setAttribute('content', to.meta.description);
  } else {
    document.querySelector('meta[name=description]')
      .setAttribute('content', 'Сам себе продюсер');
  }

  next();
});

export default router;

<template>
    <div class="webinar-list">
        <div class="es-dialog__icon-cover">
            <div class="es-dialog__icon"></div>
        </div>

        <ul class="es-dialog__list" v-if="isSinglePagePayment">
            <li v-for="(webinarName, index) in singleWebinars" :key="index">
                {{webinarName}}
            </li>
        </ul>

        <ul class="es-dialog__list" v-else>
            <li v-for="(webinarName, index) in meditationInfo.webinars" :key="index">
                {{webinarName}}
            </li>
        </ul>
    </div>
</template>

<script>

export default {
  name: 'DialogWebinarList',
  props: {
    msg: String,
  },
  components: {},
  data() {
    return {
      complete: false,
    };
  },
  computed: {
    meditationInfo() {
      return this.$store.getters.getMeditationsInfo;
    },
    isSinglePagePayment() {
      return this.$store.state.payment.isSinglePagePayment;
    },
    meditationInfoSingle() {
      return this.$store.getters.getMeditationsInfoForSingle;
    },
    singleWebinars() {
      return this.meditationInfoSingle.pendingWebinars;
    },
  },
  methods: {

  },
};
</script>

<style scoped lang="scss">
 @import "../../../assets/scss/common/variables";
 @import "../../../assets/scss/common/dialog";

.webinar-list {
  margin: 40px 0 0;

    .es-dialog__icon {
        background-image: url('/images/webinar-list-icon.svg')
    }
}
</style>

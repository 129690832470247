import Vue from 'vue';
import Vuex from 'vuex';

import general from './general';
import meditations from './meditations';
import payment from './payment';
import dialog from './dialog';
import player from './player';
import globalMessage from './globalMessage';
import user from './user';
import admin from './admin';

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    general, meditations, payment, dialog, player, globalMessage, user, admin,
  },
});
